@import '../../styles/index.scss';

.header {
  padding: 40px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $screen-sm) {
    padding: 30px 30px;
  }
  .navBar {
    display: flex;
    gap: 60px;
    @media (max-width: $screen-sm) {
      display: none;
    }
    .navItem {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 14px;
      color: #223130;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: black;
        font-weight: 500;
      }
      &.selected {
        border-bottom: 1px solid #223130;
        padding-bottom: 8px; 
      }
    }
  }

  .mobileNav {
    @media (min-width: $screen-sm) {
      display: none;
    }
    .openContainer {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #223130;
      z-index: 10;
      .topBar {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 86px;
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .menuBarWrapper {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .menuBar {
          display: flex;
          flex-direction: column;
          gap: 40px;
          .menuItem {
            font-family: 'Marcellus';
            font-weight: 400;
            font-size: 44px;
            text-align: center;
            color: #ffffff;
            opacity: 0.5;
            text-decoration: none;
            &:hover {
              color: black;
              font-weight: 500;
            }
            &.selected {
              border-bottom: 1px solid #ffffff;
              padding-bottom: 8px; 
              opacity: 1.0;
            }
          }
        }  
      }
    }
  }
}
