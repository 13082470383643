@import '../../styles/index.scss';

.section {
  position: relative;
  padding: 50px 0 160px;
  background-color: #223130;
  display: flex;
  flex-direction: column;
  @media (max-width: $screen-sm) {
    padding: 50px 0 50px;
  }
  .rowFlex {
    padding: 0 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $screen-sm) {
      padding: 0 30px;
    }
    h3 {
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 80px;
      line-height: 75px;
      color: white;
      @media (max-width: $screen-sm) {
        font-size: 30px;
        line-height: 38px;
      }
    }
    .keyContainer {
      cursor: pointer;
    }
    img {
      @media (max-width: $screen-sm) {
        width: 40px;
        height: 40px;
      }
    }
  }
  .portfoioSection {
    padding-left: 200px;
    .progressBar {
      height: 2px;
      border-radius: 2px;
      overflow: hidden;
      margin-bottom: 40px;
      background-color: rgba(255, 255, 255, 0.1);
      .activeProgressBar {
        height: 100%;
        background-color: rgba(255, 255, 255, 1.0);
      }
    }
    .sliderContainer {
      width: 100%;
      overflow: auto;
      .slider {
        display: flex;
        width: 7650px;
        overflow: hidden;
        @media (max-width: $screen-sm) {
          width: 5270px;
        }
      }  
    }
    @media (max-width: $screen-sm) {
      padding-left: 30px;
    }
  }
  .portfolioCard {
    width: 350px;;
    display: flex;
    flex-direction: column;
    margin-right: 100px;
    @media (max-width: $screen-sm) {
      width: 260px;
      margin-right: 50px;
    }
    .thumb {
      width: 100%;
    }
    .symbol {
      margin-top: 30px;
      width: 40px;
      height: 40px;
    }
    .title {
      margin-top: 18px;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
      color: white;
    }
    .description {
      margin-top: 4px;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 14px;
      color: white;
    }
  }
  a {
    text-decoration: none;
  }
}
