@import '../../../../styles/index.scss';

.section {
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 50px 180px 100px;
  @media (max-width: $screen-lg) {
    padding: 0 30px 50px;
  }
  .topSection {
    display: flex;
    flex-direction: column;
    h3 {
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 70px;
      color: #223130;
      line-height: 75px;
      @media (max-width: $screen-lg) {
        text-align: center;
        font-size: 50px;
      }
    }
    p {
      margin-top: -40px;
      width: 400px;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
      color: #223130;
      @media (max-width: $screen-lg) {
        margin-top: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
  .subSection1 {
    margin-top: 80px;
    display: flex;
    @media (max-width: $screen-lg) {
      margin-top: 0;
      flex-direction: column;
    }
    p {
      width: 400px;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
      color: #223130;
      @media (max-width: $screen-lg) {
        margin-top: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
  .leftMargin {
    margin-left: 160px;
    @media (max-width: $screen-lg) {
      margin-left: 0;
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    @media (max-width: $screen-lg) {
      margin-top: 50px;
      align-items: center;
    }
    img {
      width: 60px;
      height: 60px;
    }
    .cardSection {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 24px;
      @media (max-width: $screen-lg) {
        flex-direction: column;
      }
      .title {
        font-family: 'Marcellus';
        font-weight: 400;
        font-size: 30px;
        color: #223130;
      }
      .subtitle {
        margin-top: 12px;
        font-family: 'Marcellus';
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
        color: #223130;
      }
    }
    .description {
      width: 350px;
    }
  }
  .flexCol {
    display: flex;
    flex-direction: column;
    margin-left: 160px;
    @media (max-width: $screen-lg) {
      margin-left: 0;
    }
    .footer {
      margin-top: 100px;
      font-family: 'Marcellus';
      font-style: italic;
      font-weight: 300;
      font-size: 14px;
      color: #223130;
    }
  }
}
