@import '../../../../styles/index.scss';

.section {
  position: relative;
  margin: 0;
  height: 631px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: $screen-sm) {
    height: 600px;
  }
  p {
    width: 400px;
    font-family: 'Marcellus';
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    color: #223130;
    @media (max-width: $screen-sm) {
      width: 70%;
    }
  }
}
