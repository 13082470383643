.slick-dots li button::before {
  font-size: 8px;
  color: white;
  opacity: 0.5;
}

.slick-dots li.slick-active button::before {
  color: white;
  opacity: 1;
}
