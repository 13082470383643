@import '../../../../styles/index.scss';

.section {
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 50px 180px;
  @media (max-width: $screen-sm) {
    padding: 0 30px 50px;
  }
  .topSection {
    display: flex;
    h3 {
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 70px;
      color: #223130;
      line-height: 75px;
      @media (max-width: $screen-sm) {
        text-align: center;
        font-size: 50px;
      }
    }
  }
  .bottomSection {
    display: flex;
    flex-direction: row-reverse;
    p {
      margin-top: -100px;
      width: 400px;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
      color: #223130;
      @media (max-width: $screen-sm) {
        margin-top: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
  
}
