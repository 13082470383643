@import '../../../../styles/index.scss';

.section {
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  &.pointer {
    cursor: pointer;
  }
  h1 {
    font-family: 'Marcellus';
    font-weight: 400;
    color: black;
  }
  .backButtonContainer {
    height: 46px;
    margin: 16px 0;
    @media (max-width: $screen-md) {
      margin: 8px 0;
    }
    .backButton {
      display: flex;
      gap: 6px;
      cursor: pointer;
      p {
        font-family: 'Marcellus';
        font-weight: 400;
        font-size: 14px;
        color: #223130;
      }
    }
  }
  .imgSection {
    width: 100%;
    display: flex;
    gap: 15px;
    @media (max-width: $screen-md) {
      flex-direction: column;
    }
    .thumbnail {
      width: 380px;
      height: 380px;
      @media (max-width: $screen-md) {
        width: 100%;
        height: 330px;
      }
    }
    .mainImage {
      width: calc(100% - 380px);
      height: 380px;
      object-fit: cover;
      @media (max-width: $screen-md) {
        width: 100%;
        height: 330px;
      }
    }
  }
  .timeSection {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: 16px 0;
    @media (max-width: $screen-md) {
      flex-direction: row;
    }
    p {
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 12px;
      color: #30534B;
    }
  }
  .contentSection {
    display: flex;
    justify-content: space-between;
    @media (max-width: $screen-md) {
      flex-direction: column;
    }
    .title {
      width: 45%;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 30px;
      line-height: 38px;
      color: #223130;
      @media (max-width: $screen-md) {
        width: 100%;
      }
    }
    .descriptionSection {
      width: 45%;
      display: flex;
      flex-direction: column;
      @media (max-width: $screen-md) {
        width: 100%;
      }
      .description {
        font-family: 'Marcellus';
        font-weight: 400;
        font-size: 18px;
        color: #223130;
      }
      .moreDescription {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 90px;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
  
      }
    }
  }
  .readMoreSection {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: 8px 0;
    cursor: pointer;
    @media (max-width: $screen-md) {
      flex-direction: row;
    }
    .readMore {
      display: flex;
      align-items: center;
      p {
        font-family: 'Marcellus';
        font-weight: 400;
        font-size: 14px;
        color: #30534B;
        margin-right: 8px;
      }
    }
  }
}
