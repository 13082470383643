@import '../../../../styles/index.scss';

.section {
  margin: 0 10px;
  padding: 50px 180px;
  @media (max-width: $screen-lg) {
    margin: 0px 0px;
    padding: 0 30px 50px;
  }
  .topSection {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    @media (max-width: $screen-lg) {
      margin-top: 0px;
    }
    .landingImage2 {
      width: 306px;
      height: 380px;
      @media (max-width: $screen-lg) {
        width: 100%;
        margin-top: -60px;
      }
    }
  }
  .bottomSection {
    h3 {
      width: 430px;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 70px;
      line-height: 75px;
      color: #223130;
      @media (max-width: $screen-lg) {
        width: 100%;
        text-align: center;
      }
    }
    p {
      width: 500px;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
      color: #223130;
      @media (max-width: $screen-lg) {
        width: 100%;
        text-align: center;
      }
    }  
  }
}
