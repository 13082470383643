@import '../../../../styles/index.scss';

.section {
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  .sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sectionTitle {
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
      color: #223130;
    }
    .sectionAction {
      display: flex;
      gap: 8px;
      p {
        font-family: 'Marcellus';
        font-weight: 400;
        font-size: 18px;
        color: #223130;
      }
    }        
  }
  .sectionBody {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    @media (max-width: $screen-md) {
      grid-template-columns: 1fr;
    }
    .sectionItem {
      display: flex;
      gap: 16px;
      cursor: pointer;
      @media (max-width: $screen-md) {
        flex-direction: column;
      }   
      img {
        width: 140px;
        height: 140px;
        @media (max-width: $screen-md) {
          width: 100%;
        }
      }
      .itemContent {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        .itemTitle {
          width: 250px;
          font-family: 'Marcellus';
          font-weight: 400;
          font-size: 18px;
          color: #223130;
          @media (max-width: $screen-md) {
            width: 100%;
          }
        }
        .itemFooter {
          display: flex;
          justify-content:space-between;
          align-items: center;
          p {
            font-family: 'Marcellus';
            font-weight: 400;
            font-size: 12px;
            color: #30534B;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
