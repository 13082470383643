@import '../../../styles/index.scss';
.popupContainer {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 956px;
    padding: 100px 140px;
    background-color: white;
    border-radius: 8px;
    
    @media (max-width: $screen-md) {
      padding: 80px 80px;
    }

    @media (max-width: $screen-sm) {
      overflow-y: scroll;
      padding: 70px 60px 50px;
    }
    .closeContainer {
      position: absolute;
      top: 33px;
      right: 33px;
      cursor: pointer;
      @media (max-width: $screen-sm) {
        top: 26px;
        right: 26px;
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
    .title {
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 30px;
      text-align: center;
      color: #223130;
      @media (max-width: $screen-sm) {
        font-size: 24px;
      }
    }
    .body {
      margin-top: 80px;
      display: flex;
      column-gap: 140px;
      @media (max-width: $screen-sm) {
        margin-top: 40px;
      }
      @media (max-width: $screen-md) {
        column-gap: 80px;
      }
      @media (max-width: $screen-sm) {
        flex-direction: column;
        row-gap: 20px;
      }
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: $screen-sm) {
          img {
            width: 64px;
            height: 64px;
          }
        }
        p {
          font-family: 'Marcellus';
          font-weight: 400;
          font-size: 18px;
          color: #223130;
          @media (max-width: $screen-sm) {
            font-size: 16px;
          }
        }
      }
    }
  }
}