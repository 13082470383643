@import '../../../../styles/index.scss';

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  margin: 0 20px;
  background-color: #F4F3F1;
  padding: 50px 0;
  @media (max-width: $screen-sm) {
    padding: 30px 0;
  }
  h3 {
    font-family: 'Marcellus';
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    color: #223130;
    @media (max-width: $screen-sm) {
      font-size: 30px;
    }
  }
  .list {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    @media (max-width: $screen-sm) {
      display: grid;
      grid-row-gap: 16px;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }
  }
  .card {
    width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
      color: #223130;
      text-align: center;
      @media (max-width: $screen-sm) {
        font-size: 16px;
      }
    }
  }
}
