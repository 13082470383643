@import '../../../styles/index.scss';

.container {
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  padding: 20px 100px;
  margin: auto;
  @media (max-width: $screen-md) {
    padding: 0 40px;
  }
  @media (max-width: $screen-sm) {
    padding: 0 16px;
  }

  .backButtonContainer {
    height: 46px;
    margin: 16px 0;
    @media (max-width: $screen-md) {
      margin: 8px 0;
    }
    .backButton {
      display: flex;
      gap: 6px;
      cursor: pointer;
      p {
        font-family: 'Marcellus';
        font-weight: 400;
        font-size: 14px;
        color: #223130;
      }
    }
  }

  .bannerImageContainer {
    display: flex;
    column-gap: 10px;
    @media (max-width: $screen-sm) {
      flex-direction: column;
      row-gap: 10px;
    }
    .thumbnailContainer {
      width: 380px;
      height: 380px;
      border: 1px dashed #30534B;
      @media (max-width: $screen-md) {
        width: 200px;
        height: 200px;
      }
      @media (max-width: $screen-sm) {
        width: 100%;
      }
    }
    .bannerContainer {
      flex-grow: 1;
      height: 380px;
      border: 1px dashed #30534B;
      @media (max-width: $screen-md) {
        height: 200px;
      }
    }
    .dropzone {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        h3 {
          font-family: 'Marcellus';
          font-weight: 400;
          font-size: 30px;
          color: black;
          margin-block-start: 0;
          margin-block-end: 0;
        }
        p {
          font-family: 'Montserrat';
          font-weight: 400;
          font-size: 30px;
          text-align: center;
          color: #223130;
          margin-block-start: 0;
          margin-block-end: 0;
        }
      }
    }
  }
  .inputContainer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
    input {
      padding: 16px;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
    }
  }
  .inputDescription {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 100%;
    textarea {
      min-height: 280px;
      padding: 16px;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
      resize: none;
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
    .button {
      height: 56px;
      border: 1px solid #223130;
      border-radius: 4px;
      background: #223130;
      color: white;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .error {
    margin-top: 6px;
    font-family: 'Marcellus';
    font-weight: 400;
    font-size: 12px;
    color: red;
  }
}
