@import '../../../../styles/index.scss';

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  margin: 0;
  height: 920px;
  background-color: #223130;
  @media (max-width: $screen-sm) {
    height: 600px;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    h3 {
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 60px;
      text-align: center;
      color: white;
      @media (max-width: $screen-sm) {
        font-size: 30px;
      }
    }
    p {
      width: 400px;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
      color: white;
      text-align: center;
      @media (max-width: $screen-sm) {
        width: 100%;
        font-size: 16px;
      }
    }
  }
}
