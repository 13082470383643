@import '../../styles/index.scss';

.footer {
  position: relative;
  padding: 100px 160px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $screen-md) {
    padding: 80px 40px 80px;
  }
  @media (max-width: $screen-sm) {
    flex-direction: column;
    padding: 80px 30px 80px;
    align-items: flex-start;
  }
  .left {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .contactUs {
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
      color: #223130;
    }
    .title {
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 30px;
      color: #223130;
    }
    .info {
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
      color: #223130;
      opacity: 0.5;
    }
  }
  .right {
    display: flex;
    justify-content: space-between;
    @media (max-width: $screen-sm) {
      margin-top: 40px;
      width: 100%;
    }
    .flexRow {
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      .item {
        font-family: 'Marcellus';
        font-weight: 400;
        font-size: 18px;
        color: #223130;
        margin-top: 16px;
      }  
    }
  }
  .copyRight {
    position: absolute;
    bottom: 30px;
    font-family: 'Marcellus';
    font-weight: 400;
    font-size: 14px;
    color: #223130;
  }
}
