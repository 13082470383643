@import '../../styles/index.scss';

.container {
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  h3 {
    font-family: 'Marcellus';
    font-weight: 400;
    font-size: 80px;
    text-align: center;
    color: #223130;
    @media (max-width: $screen-sm) {
      font-size: 40px;
    }
  }
}
