@import '../../../../styles/index.scss';

.section {
  padding: 100px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .sectionTitle {
    font-family: 'Marcellus';
    font-weight: 400;
    font-size: 30px;
    color: #223130;
    text-align: center;
  }
  p {
    font-family: 'Marcellus';
    font-weight: 400;
    font-size: 14px;
    color: #223130;
    text-align: center;
  }
  .sectionInput {
    display: flex;
    width: 460px;
    height: 48px;
    @media (max-width: $screen-md) {
      width: 100%;
    }
    .inputField {
      flex-grow: 1;
      height: 46px;
      padding: 0 16px;
      display: inline-block;
      border: 1px solid #223130;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 14px;
      color: #223130;
    }
    .button {
      width: 146px;
      height: 100%;
      border: 1px solid #223130;
      background: #223130;
      color: white;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
      @media (max-width: $screen-md) {
        width: 120px;
      }
    }
  }
}
