@import '../../../styles/index.scss';

.container {
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  padding: 20px 100px;
  margin: auto;
  @media (max-width: $screen-md) {
    padding: 0 40px;
  }
  @media (max-width: $screen-sm) {
    padding: 0 16px;
  }

  .section {
    margin: 10px 20px;
    display: flex;
    flex-direction: column;
    .sectionHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .sectionTitle {
        font-family: 'Marcellus';
        font-weight: 400;
        font-size: 18px;
        color: #223130;
      }
      .createButton {
        height: 32px;
        padding: 0 20px;
        border: 1px solid #223130;
        font-family: 'Marcellus';
        font-weight: 400;
        font-size: 14px;
        color: #223130;
        background-color: white;
        cursor: pointer;
      }        
    }
    .sectionBody {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      .sectionItem {
        display: flex;
        justify-content: space-between;
        @media (max-width: $screen-md) {
          flex-direction: column;
        }
        .leftItem {
          display: flex;
          gap: 16px;
          @media (max-width: $screen-md) {
            flex-direction: column;
          }   
          img {
            width: 140px;
            height: 140px;
            @media (max-width: $screen-md) {
              width: 100%;
            }
            object-fit: cover;
          }
          .itemContent {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content:space-between;
            .itemTitle {
              width: 250px;
              font-family: 'Marcellus';
              font-weight: 400;
              font-size: 18px;
              color: #223130;
              @media (max-width: $screen-md) {
                width: 100%;
              }
            }
            .itemFooter {
              display: flex;
              justify-content:space-between;
              align-items: center;
              p {
                font-family: 'Marcellus';
                font-weight: 400;
                font-size: 12px;
                color: #30534B;
              }
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
        .rightItem {
          display: flex;
          align-items: center;
          column-gap: 16px;
          .actionIcon {
            width: 32px;
            height: 32px;
          }
          .editButton {
            height: 32px;
            padding: 0 20px;
            border: 1px solid #000000;
            font-family: 'Marcellus';
            font-weight: 400;
            font-size: 15px;
            color: #000000;
            background-color: white;
            cursor: pointer;
          }
          .deleteButton {
            height: 32px;
            padding: 0 20px;
            border: 1px solid #EF5A5A;
            font-family: 'Marcellus';
            font-weight: 400;
            font-size: 15px;
            color: #EF5A5A;
            background-color: white;
            cursor: pointer;
          }
        }
      }
    }
  }
}
