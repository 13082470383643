@import '../../styles/index.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 20px 160px;
  @media (max-width: $screen-md) {
    padding: 0 0;
  }
}
