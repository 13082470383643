@import '../../../../styles/index.scss';

.section {
  margin: 10px 20px;
  height: calc(100vh - 110px);
  min-height: 750px;
  background: #223130;
  display: flex;
  justify-content: space-between;
  padding: 0 180px;
  @media (max-width: $screen-lg) {
    height: auto;
    min-height: calc(100vh - 80px);
    margin: 0px 0px;
    padding: 0 0 80px;
    flex-direction: column;
  }
  .left {
    position: relative;
    margin-top: 160px;
    @media (max-width: $screen-lg) {
      margin-top: 50px;
    }
    h1 {
      width: 600px;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 70px;
      color: white;
      @media (max-width: $screen-lg) {
        width: 100%;
        font-size: 40px;
        text-align: center;
      }
    }
    .landingImage1 {
      position: absolute;
      left: 0;
      bottom: -250px;
      width: 560px;
      height: 388px;
      @media (max-width: $screen-lg) {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 60px;
    @media (max-width: $screen-lg) {
      padding: 0 30px;
    }
    span {
      width: 350px;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
      color: white;
      @media (max-width: $screen-lg) {
        margin-top: 60px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
