@import '../../../../styles/index.scss';

.section {
  position: relative;
  margin: 0;
  height: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: $screen-sm) {
    margin: 0px 0px;
  }
  .landingImage1 {
    position: absolute;
    left: 100px;
    top: -180px;
    width: 481px;
    height: 495px;
    @media (max-width: $screen-sm) {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      object-fit: cover;
    }
  }
  .landingImage2 {
    position: absolute;
    right: 100px;
    top: 100px;
    width: 481px;
    height: 495px;
    @media (max-width: $screen-sm) {
      position: relative;
      width: 100%;
      right: 0;
      top: 0;
      object-fit: cover;
    }
  }
  p {
    position: absolute;
    width: 530px;
    bottom: 140px;
    font-family: 'Marcellus';
    font-weight: 400;
    font-size: 30px;
    color: #223130;
    text-align: center;
    @media (max-width: $screen-sm) {
      width: 70%;
      position: relative;
      bottom: 0;
    }
  }
}
