@import '../../../../styles/index.scss';

.section {
  position: relative;
  margin: 0 20px 10px;
  height: calc(100vh - 110px);
  background: #F4F3F1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: $screen-sm) {
    height: calc(100vh - 80px);
    margin: 0px 0px;
  }
  .sectionTitle {
    position: relative;
    h1 {
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 70px;
      text-align: center;
      color: #223130;
      white-space: pre;
      @media (max-width: $screen-sm) {
        font-size: 40px;
      }
    }
    span {
      position: absolute;
      right: -280px;
      bottom: 12px;
      font-family: 'Marcellus';
      font-weight: 400;
      font-size: 18px;
      color: #223130;
      white-space: pre;
      @media (max-width: $screen-sm) {
        position: relative;
        font-size: 14px;
        text-align: center;
        right: 0;
        bottom: 0;
      }
    }
  }
  .scrollContainer {
    position: absolute;
    bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    @media (max-width: $screen-sm) {
      bottom: -64px;
      z-index: 1;
    }
    .rotateContainer {
      position: relative;
      span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        text-align: center;
        color: #223130
      }
    }
    .rotate {
      width: 68px;
      height: 68px;
      animation: rotation 4s infinite linear;
    }
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
    .arrowDown {
      width: 56px;
      margin-top: 16px;
    }
  }
}
