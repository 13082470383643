@import '../../../../styles/index.scss';

.section {
  margin: 0;
  img {
    width: 90%;
    height: auto;
    @media (max-width: $screen-sm) {
      width: 100%;
      height: 400px;
    }
  }
}
